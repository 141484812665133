import React, { Component } from "react";
import * as ReactScroll from "react-scroll";
import styled from "styled-components";
import { withPage } from "../../Page";
import * as Widget2 from "../../Components/Widget2";

const Items = [
  {
    title: "嬰兒室",
    name: "baby-room",
    reverse: false,
    image: "photo10.png",
    intro1:
      "嬰兒室區分為檢查室、主嬰兒室與觀察室，均設有醫療級空氣清淨除菌機、飛利浦AC5659奈米級抗敏空氣清淨機、奶瓶消毒鍋、獨立的母乳冰庫及正負 壓全熱交換機與獨立空調，杜絕寶寶交互感染機率。",
    intro2:
      "設置防焰一級防爆玻璃窗與24小時恆溫恆濕不斷電系統，確保 嬰兒室可以獨立正常運作，不受任何外界因素所影響。",
    intro3:
      " 全程照護透明化，嬰兒室中的寶寶皆有一對一的網連視訊，可透過網路輸入專屬帳號密碼，觀賞即時的寶寶影像。"
  },
  {
    title: "房型Ａ",
    name: "room-type-a",
    reverse: true,
    image: "photo10.png",
    intro1:
      "嬰兒室區分為檢查室、主嬰兒室與觀察室，均設有醫療級空氣清淨除菌機、飛利浦AC5659奈米級抗敏空氣清淨機、奶瓶消毒鍋、獨立的母乳冰庫及正負 壓全熱交換機與獨立空調，杜絕寶寶交互感染機率。",
    intro2:
      "設置防焰一級防爆玻璃窗與24小時恆溫恆濕不斷電系統，確保 嬰兒室可以獨立正常運作，不受任何外界因素所影響。",
    intro3:
      " 全程照護透明化，嬰兒室中的寶寶皆有一對一的網連視訊，可透過網路輸入專屬帳號密碼，觀賞即時的寶寶影像。"
  },
  {
    title: "房型Ｂ",
    name: "room-type-b",
    reverse: false,
    image: "photo10.png",
    intro1:
      "嬰兒室區分為檢查室、主嬰兒室與觀察室，均設有醫療級空氣清淨除菌機、飛利浦AC5659奈米級抗敏空氣清淨機、奶瓶消毒鍋、獨立的母乳冰庫及正負 壓全熱交換機與獨立空調，杜絕寶寶交互感染機率。",
    intro2:
      "設置防焰一級防爆玻璃窗與24小時恆溫恆濕不斷電系統，確保 嬰兒室可以獨立正常運作，不受任何外界因素所影響。",
    intro3:
      " 全程照護透明化，嬰兒室中的寶寶皆有一對一的網連視訊，可透過網路輸入專屬帳號密碼，觀賞即時的寶寶影像。"
  },
  {
    title: "SPA",
    name: "spa",
    reverse: true,
    image: "photo10.png",
    intro1:
      "嬰兒室區分為檢查室、主嬰兒室與觀察室，均設有醫療級空氣清淨除菌機、飛利浦AC5659奈米級抗敏空氣清淨機、奶瓶消毒鍋、獨立的母乳冰庫及正負 壓全熱交換機與獨立空調，杜絕寶寶交互感染機率。",
    intro2:
      "設置防焰一級防爆玻璃窗與24小時恆溫恆濕不斷電系統，確保 嬰兒室可以獨立正常運作，不受任何外界因素所影響。",
    intro3:
      " 全程照護透明化，嬰兒室中的寶寶皆有一對一的網連視訊，可透過網路輸入專屬帳號密碼，觀賞即時的寶寶影像。"
  },
  {
    title: "其他設施",
    name: "else",
    reverse: false,
    image: "photo10.png",
    intro1:
      "嬰兒室區分為檢查室、主嬰兒室與觀察室，均設有醫療級空氣清淨除菌機、飛利浦AC5659奈米級抗敏空氣清淨機、奶瓶消毒鍋、獨立的母乳冰庫及正負 壓全熱交換機與獨立空調，杜絕寶寶交互感染機率。",
    intro2:
      "設置防焰一級防爆玻璃窗與24小時恆溫恆濕不斷電系統，確保 嬰兒室可以獨立正常運作，不受任何外界因素所影響。",
    intro3:
      " 全程照護透明化，嬰兒室中的寶寶皆有一對一的網連視訊，可透過網路輸入專屬帳號密碼，觀賞即時的寶寶影像。"
  }
];

class EnvironmentPage extends Component {
  render() {
    return (
      <Wrapper>
        <Widget2.Banner img="/images/banner04.png" css="margin-bottom: 40px;">
          <div className="mist">
            <div className="title">環境介紹</div>
            <div className="slogan">舒適環境，坐月子像度假一樣</div>
          </div>
        </Widget2.Banner>

        <Container>
          <AnchorBar css="margin-bottom: 74px;">
            {Items.map((item, i) => (
              <ReactScroll.Link
                key={"anchor-item" + i}
                to={item.name}
                smooth={true}
                offset={-180}
                className="btn"
              >
                {item.title}
              </ReactScroll.Link>
            ))}
          </AnchorBar>

          {Items.map((item, i) => (
            <EnvironmentItem
              name={item.name}
              key={"environment-item" + i}
              reverse={item.reverse}
              img={`/images/${item.image}`}
            >
              <div className="img" />
              <div className="text">
                <div className="title">{item.title}</div>
                <div className="intro">{item.intro1}</div>
                <div className="intro">{item.intro2}</div>
                <div className="intro">{item.intro3}</div>
              </div>
            </EnvironmentItem>
          ))}
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding-top: 100px;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1181px;
  padding: 20px;
`;

const AnchorBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  & > .btn {
    border-right: 1px solid #101d1f;
    padding: 0px 20px;
    min-width: 56px;
    font-size: 16px;
    color: #71c6d0;
    letter-spacing: 1.6px;
    cursor: pointer;
  }

  ${props => props.css};
`;

const EnvironmentItem = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  align-items: center;
  margin-bottom: 127px;

  & > .img {
    margin-right: ${props => (props.reverse ? 0 : 20)}px;
    max-width: 781px;
    min-height: 539px;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  & > .text {
    max-width: 380px;
    margin-right: ${props => (props.reverse ? 20 : 0)}px;
    & > .title {
      margin-bottom: 18px;
      font-weight: bold;
      font-size: 28px;
      color: #71c6d0;
      letter-spacing: 3px;
    }

    & > .intro {
      margin-bottom: 15px;
      white-space: pre-wrap;
      font-size: 14px;
      color: rgba(49, 34, 34, 0.6);
      letter-spacing: 1.6px;
    }
  }

  @media screen and (max-width: 414px) {
    flex-wrap: wrap;

    & > .img {
      margin-right: 0px;
      margin-bottom: 10px;
    }

    & > .text {
      margin-right: 0px;
      & > .title {
        text-align: center;
      }
    }
  }
`;

export default withPage(EnvironmentPage);
